// src/pages/log/index.tsx
import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    Stack,
    Spacer,
    Flex,
    Button,
    Center,
    Link as ChakraLink,
} from "@chakra-ui/react";
// import { useRouter } from 'next-routes';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';

// A page that takes an opporutnityID from the url and then fetches the opportunity from the API and displays it


import { NavBarLogic } from "../../components/NavBarLogic";
import { Footer } from "../../components/Footer";
import { useAuth } from "../../contexts/AuthContext";
import { getLogByLoggerID } from '../../utils/loggerSerice';
import { getEventsByLoggerID } from "../../utils/eventService";

import { getApiUrl } from '../../utils/helpers';

import { Logger, Photo, Event, User } from '../../types';
import { NewEventComponent } from "../../components/NewEventComponent";
import { EventsComponent } from "../../components/EventsComponent";



import { fetchPendingPhotos } from "../../utils/photoService";

import { EventIDDisplay } from "../../components/EventIDDisplay";

import { fetchUserByEthAddress } from "../../utils/userService";
import TokenDistributionChart from "../../components/TokenDistributionChart";
import TokenInfoModal from "../../components/TokenInforModel";









export const Log = () => {
    const apiUrl = getApiUrl();
    const { auth, logout, setAuthInfo } = useAuth();
    const [events, setEvents] = useState<Event[]>([]); // State for events
    const [logDetails, setLogDetails] = useState<Logger | null>(null); // Correctly type the state
    const [pendingPhotos, setPendingPhotos] = useState<Photo[]>([]); // State for pending photos
    const [showNewEventCompnent, setShowNewEventCompnent] = useState(true); // State for showing the new event component
    const [userObjectFromMongo, setUserObjectFromMongo] = useState<User | null>(null);
    const [wasSTN5, setWasSTN5] = useState(false);
    const pathSegments = window.location.pathname.split('/');
    const loggerID = pathSegments[pathSegments.length - 1]; // Assuming loggerID is the last segment
    const navigate = useNavigate();
    const [hasStoredToken, setHasStoredToekn] = useState(false);
    const [inviteCode, setInviteCode] = useState<String>("");
    const [showTransactions, setShowTransactions] = useState(false)
    const [showTokenDistribution, setShowTokenDistribution] = useState(false)




    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('inviteCode');
        if (code) {
            setInviteCode(code);
            console.log("Invite Code:", code);  // Should log the correct invite code
        } else {
            console.log("No invite code found.");
        }
    }, []);





    /**
* Listens for changes to the `userObjectFromMongo` state and logs the updated value to the console.
* This effect is used to monitor and respond to changes in the user object retrieved from the MongoDB database.
*/
    useEffect(() => {
        if (userObjectFromMongo) {
            console.log("Updated userObjectFromMongo:", userObjectFromMongo);
        }
    }, [userObjectFromMongo]); // Dependency array includes userObjectFromMongo to listen for changes

    /**
    * This effect checks for stored user data in the browser's local storage and fetches the user's information if it exists.
    * 
    * - If there is a stored Ethereum address, it calls `fetchUserByEthAddress` to retrieve the user's data.
    * - If there are stored token, phone number, and Ethereum address, it creates a new `User` object with the stored data and sets it in the component's state.
    * - It also sets the `authInfo` state with the stored token, phone number, Ethereum address, and login method.
    */
    useEffect(() => {
        const storedToken = localStorage.getItem('token') ?? '';
        const storedPhone = localStorage.getItem('phoneNumber') ?? '';
        const storedAddress = localStorage.getItem('user') ?? ''; // Ensure you store and retrieve the same 
        if (storedToken && storedPhone && storedAddress) {
            // console.log("1. Ethereum addrsss from browser: " + storedAddress)
            setHasStoredToekn(true);  // Set true if token exists


            const fetchData = async () => {
                try {
                    const userAlreadySavedWithMongoDB = await fetchUserByEthAddress(storedAddress, apiUrl);

                    // If userAlreadySavedWithMongoDB is not null, then we have a user in the database
                    // with the same address as the one stored in the browser
                    if (userAlreadySavedWithMongoDB) {
                        console.log("2. User already saved with MongoDB:", userAlreadySavedWithMongoDB);
                        setUserObjectFromMongo(userAlreadySavedWithMongoDB);
                        //todo: if they have the permission "STN5" make the wasSTN5 to true

                        // Check for "STN5" permission
                        const hasSTN5Permission = userAlreadySavedWithMongoDB.permissions?.includes("STN5");
                        if (hasSTN5Permission)
                            setWasSTN5(hasSTN5Permission);
                        // { example user object from mongodb
                        // "_id": "66551d6d23af09921e7efdb3",
                        //     "phoneNumber": "+61458944327",
                        //         "addressETH": "0x2B22563d34591a34E8E28380ded38016D8A7dCA1",
                        //             "permissions": [
                        //                 "STN5"
                        //             ],
                        //                 "createdAt": "2024-05-27T23:55:25.306Z",
                        //                     "updatedAt": "2024-06-05T01:19:48.770Z",
                        //                         "__v": 0,
                        //                             "firstName": "Tim",
                        //                                 "lastName": "Samuel"
                        // }
                    } else {
                        console.log("3. User not already saved with MongoDB");
                        // Additional code to handle when the user is not found
                    }
                } catch (error) {
                    console.error("Failed to fetch user:", error);
                }
            }

            fetchData();
        }
    }, []);


    useEffect(() => {
        const fetchLogDetails = async () => {
            if (!loggerID) return; // Ensure loggerID is defined
            try {
                const log = await getLogByLoggerID(apiUrl, loggerID);
                setLogDetails(log);
                console.log("What came back from log details: ", log);
            } catch (error) {
                console.error("Failed to fetch log details:", error);
            }
        };

        fetchLogDetails();
    }, [apiUrl, loggerID]);

    const formatDate = (date: Date | undefined) => {
        return date ? new Date(date).toLocaleString() : '';
    };

    useEffect(() => {
        const fetchPhotos = async () => {
            console.log("Fetching pending photos");
            if (!auth.userAddress) return;
            try {
                const photos = await fetchPendingPhotos(auth.userAddress, apiUrl);
                setPendingPhotos(photos);
                console.log(photos);
            } catch (error) {
                console.error("Failed to fetch pending photos:", error);
            }
        };

        fetchPhotos();
    }, [apiUrl, auth.userAddress]);

    // todo: get the events by logger id via: getEventsByLoggerID 
    useEffect(() => {
        const fetchEvents = async () => {
            console.log("Fetching events");
            if (!loggerID) return;
            try {
                const events = await getEventsByLoggerID(apiUrl, loggerID);
                // Check if the user is logged in
                if (events) {
                    // Pass only the first 4 events if not logged in
                    setEvents(events);
                    // setEvents(events.slice(0, 4));
                } else {
                    // Pass all events if logged in
                    setEvents(events);
                }
                console.log(events);
            } catch (error) {
                console.error("Failed to fetch events:", error);
            }
        };

        fetchEvents();
    }, [apiUrl, loggerID]);

    const handleLogout = async () => {
        await logout();
        navigate('/smslogin');
    };


    const navigateToLogin = () => {
        // Construct the URL with the inviteCode
        const loginUrl = `/smslogin?inviteCode=${inviteCode}`;
        navigate(loginUrl);
    };

    const navigateToBookingCalendar = () => {
        const bookingCalendarURL = `/calendar/${loggerID}`
        navigate(bookingCalendarURL)
    }


    const handlShowTransactions = () => {
        if (logDetails?.managementAccountDetails?.accountAddress) {
          navigate(`/transactions/${loggerID}/${logDetails.managementAccountDetails.accountAddress}/${logDetails?.managementAccountDetails?.chain}/${logDetails?.managementAccountDetails?.fromBlock}`);
        }
      };


    const handlShowTokenDistribution = () => {
        setShowTokenDistribution(!showTokenDistribution)
    }

    return (
        <>
            <NavBarLogic></NavBarLogic>




            <Box as="section" bg="gray.50" m={1} p={1}>
                <Container py={{ base: "2", md: "4" }} maxW={800}>
                    <Flex>
                        {/* <Box p='4' bg='red.400'>
                            Box 1
                        </Box> */}
                        <Spacer />
                        <Box p='4' >
                            {/* todo: e.g. call time on thursday at 3pm then show the button for 2 hours at that time.
                            so if the time is between thursday 4pm and 6pm then show them the button othwise show them 
                            
                            He we have our first live call on thursday at 6pm, come back to this page at 6pm an there will be a link to join by your phone.


                            */}
                            {/* <ChakraLink href="https://chat.smarttradenetworks.com/MeaningfulAttributesDevastateBrightly" target="_blank" isExternal>
                                <Button colorScheme='green'>Join Live Call  </Button>
                            </ChakraLink> */}
                            {!hasStoredToken && <Center>
                                <Button
                                    mt={5}
                                    onClick={navigateToLogin}
                                    size='lg'
                                    height='48px'
                                    width='inherit'
                                    variant='outline'
                                    border='2px'
                                    colorScheme="green"
                                    borderColor='green.500'>Login to learm more</Button>

                            </Center>}
                        </Box>
                    </Flex>
                    <Stack spacing={{ base: "8", md: "10" }}>
                        <Stack spacing={{ base: "4", md: "5" }} align="center">
                            <Image
                                src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${logDetails?.photoLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`}
                                alt={logDetails?.description}
                                borderRadius='lg'
                            />
                            <Heading size='md'>{logDetails?.name}</Heading>

                        </Stack>


                        <Box ml={1}>

                            {/* <Text >
                                TODO: Query all transactions on the account and show the transactions that are not linked to an event.
                            </Text> */}

                            {/* <Box ml={1}>
                                {wasSTN5 && (loggerID === "0x4587e21467f5960efc50f6eb59684d0d9c54b919895577b9a26ba93826f3304f" || loggerID === "0xb8e5043c3a1c745e8772badb57ca8d9bad3a8d0c3b5f74774e195c5bc2a2c204") &&
                                    <TokenDistributionChart loggerID={loggerID} contractAddress="0x55150E8378B81F930f310d4B62AB7e1252c30B9B" />}
                            </Box> */}


                            <Box ml={1}>
                                {(loggerID === "0x4587e21467f5960efc50f6eb59684d0d9c54b919895577b9a26ba93826f3304f" || loggerID === "0xb8e5043c3a1c745e8772badb57ca8d9bad3a8d0c3b5f74774e195c5bc2a2c204") &&
                                    <div>

                                        {showTokenDistribution && <TokenDistributionChart loggerID={loggerID} contractAddress="0x55150E8378B81F930f310d4B62AB7e1252c30B9B" hasStoredToken={hasStoredToken} />}
                                        {!showTokenDistribution && <Button m={4} onClick={handlShowTokenDistribution} colorScheme="blue">Show Token Distribution</Button>}
                                        <TokenInfoModal />
                                        {/* {showTransactions && <Transactions chain="0x1" tokenAddress="0xdac17f958d2ee523a2206206994597c13d831ec7" accountAddress="0x3338F41E7Aaa4d31dB3d32BD7c1F790F672563aa" fromBlock="14529356" loggerID={loggerID}></Transactions>} */}
                                        {!showTransactions && <Button m={4} onClick={handlShowTransactions} colorScheme="blue">Show All Transactions</Button>}
                                    </div>}



                            </Box>





                            {/* <Flex> */}

                            {!hasStoredToken && <Center>
                                <Button
                                    mt={5}
                                    onClick={navigateToLogin}
                                    size='lg'
                                    height='48px'
                                    width='inherit'
                                    variant='outline'
                                    border='2px'
                                    colorScheme="green"
                                    borderColor='green.500'>Login to learm more</Button>

                            </Center>}



                            <Text >

                            </Text>
                            {/* </Flex> */}
                        </Box>

                        <Box ml={1}>
                            <Text >
                                {logDetails?.description}
                            </Text>

                            {loggerID &&
                                <EventIDDisplay eventID={loggerID} eventOrLoggerID="loggerID" />}
                            <Flex>
                                <Text fontSize='sm' ><b>Created: </b></Text>
                                <Text fontSize='sm' color='gray.500'>
                                    {formatDate(logDetails?.createdAt)}
                                </Text>
                            </Flex>
                        </Box>

                    </Stack>



                    <Box>
                        {loggerID && <EventsComponent loggerID={loggerID} events={events}></EventsComponent>}
                    </Box>
                    <Spacer></Spacer>



                    <Flex direction={"column"} p={4}>
                        {/* <Center padding={4} m={4}> */}
                        {/* <Button onClick={toggleNewEventComponent} p={4}>Report Event</Button> */}
                        <Heading as='h6' size='xs' > Report Event</Heading>
                        <Spacer></Spacer>
                        {/* {showNewEventCompnent && loggerID && pendingPhotos.length > 0 && auth.userAddress && ( */}
                        <Box mt={4}>
                            {loggerID && showNewEventCompnent && hasStoredToken &&
                                <NewEventComponent loggerID={loggerID} userAddress={auth.userAddress} />}
                            {/* )} */}
                            {/* </Center> */}
                            <Spacer></Spacer>

                            {!hasStoredToken && <Center>
                                <Button onClick={navigateToLogin}>Login</Button>

                            </Center>}

                            <Text>
                                {auth.token ? `Welcome back, user ${auth.phoneNumber}!` : 'Not logged in.'}
                            </Text>





                        </Box>
                        {hasStoredToken && <Button colorScheme="blue" onClick={handleLogout}>
                            Logout
                        </Button>}


                        {/* Conditional rendering of the Camera component */}
                        {/* {userObjectFromMongo && <Camera onCapture={handleCapture} />} */}


                    </Flex>

                    <Flex direction={"column"} p={4}>

                        {/* <UploadFromGallery userAddress={auth.userAddress}/> */}

                    </Flex>

                </Container>
            </Box>

            <Box id="footer">
                <Footer />
            </Box>
        </>
    );
};
