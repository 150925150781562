// src/utils/transactionService.ts
import { Transaction } from '../types';  // Assuming Transaction is defined in your types

// Function to create a new transaction
export const createTransaction = async (
    apiUrl: string,
    transactionData: {
        transactionHash: string;
        eventIDs: string[];
        description?: string;
        loggerID: string
    }
): Promise<Transaction> => {
    try {
        const response = await fetch(`${apiUrl}/api/transaction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(transactionData)
        });

        if (!response.ok) {
            throw new Error('Failed to create transaction');
        }

        const createdTransaction: Transaction = await response.json();
        return createdTransaction;
    } catch (error) {
        console.error('Error creating transaction:', error);
        throw error;
    }
};

// Function to get all transactions
export const getAllTransactionMetaData = async (apiUrl: string): Promise<Transaction[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/transaction`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch transactions');
        }

        const transactions: Transaction[] = await response.json();
        return transactions;
    } catch (error) {
        console.error('Error fetching transactions:', error);
        throw error;
    }
};

// Function to get a single transaction by ID
export const getTransactionById = async (apiUrl: string, id: string): Promise<Transaction> => {
    try {
        const response = await fetch(`${apiUrl}/api/transactions/id/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch transaction');
        }

        const transaction: Transaction = await response.json();
        return transaction;
    } catch (error) {
        console.error('Error fetching transaction:', error);
        throw error;
    }
};

// Function to update a transaction
export const updateTransaction = async (
    apiUrl: string,
    id: string,
    transactionData: Partial<Transaction>
): Promise<Transaction> => {
    try {
        const response = await fetch(`${apiUrl}/api/transactions/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(transactionData)
        });

        if (!response.ok) {
            throw new Error('Failed to update transaction');
        }

        const updatedTransaction: Transaction = await response.json();
        return updatedTransaction;
    } catch (error) {
        console.error('Error updating transaction:', error);
        throw error;
    }
};

// Function to delete a transaction
export const deleteTransaction = async (apiUrl: string, id: string): Promise<void> => {
    try {
        const response = await fetch(`${apiUrl}/api/transactions/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to delete transaction');
        }
    } catch (error) {
        console.error('Error deleting transaction:', error);
        throw error;
    }
};

// Function to get transactions by logger ID
export const getTransactionsByLoggerId = async (apiUrl: string, loggerID: string): Promise<Transaction[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/transactions/logger/${loggerID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch transactions by logger ID');
        }

        const transactions: Transaction[] = await response.json();
        return transactions;
    } catch (error) {
        console.error('Error fetching transactions by logger ID:', error);
        throw error;
    }
};

// Function to get a transaction by its hash
export const getTransactionByHash = async (apiUrl: string, transactionHash: string): Promise<Transaction> => {
    try {
        const response = await fetch(`${apiUrl}/api/transactions/hash/${transactionHash}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch transaction by hash');
        }

        const transaction: Transaction = await response.json();
        return transaction;
    } catch (error) {
        console.error('Error fetching transaction by hash:', error);
        throw error;
    }
};

// Function to get transactions by event ID(s)
export const getTransactionsByEventIds = async (apiUrl: string, eventIDs: string | string[]): Promise<Transaction[]> => {
    try {
        const queryParams = Array.isArray(eventIDs) ? eventIDs.join(',') : eventIDs;
        const response = await fetch(`${apiUrl}/api/transactions/events?eventIDs=${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch transactions by event ID(s)');
        }

        const transactions: Transaction[] = await response.json();
        return transactions;
    } catch (error) {
        console.error('Error fetching transactions by event ID(s):', error);
        throw error;
    }
};