// src/pages/transactions/index.tsx
import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
    Box,
    Container,
    Text,
    VStack,
    Badge,
    useBreakpointValue,
    useColorModeValue,
    Flex,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    Button
} from "@chakra-ui/react";
import { useParams } from 'react-router-dom';
import { getAllTransactionsFromMoralis } from '../../utils/moralisService';
import { getAllTransactionMetaData } from '../../utils/transactionService';
import { NavBarLogic } from "../../components/NavBarLogic";
import { Footer } from "../../components/Footer";
import { useAuth } from "../../contexts/AuthContext";
import { getApiUrl } from '../../utils/helpers';
import { format } from 'date-fns'; // Import date-fns for date formatting
import { Link as RouterLink } from 'react-router-dom';

import { Transaction } from "../../types"
import { getEventById } from "../../utils/newEventBackendService";
import { useNavigate } from 'react-router-dom';

export const TransactionsPage = () => {
    const apiUrl = getApiUrl();
    const { auth } = useAuth();
    const { loggerID, accountNumber, chain, fromBlock } = useParams<{ loggerID: string, accountNumber: string, chain: string, fromBlock: string }>();
    const [moralisTransactions, setTransactions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [usdToAudRate] = useState<number>(1.4);
    const [balance, setBalance] = useState<number>(0);
    const [transactionMetaData, setTransactionMetaData] = useState<Transaction[]>([]);

    // Add this new state to store the event links
    const [eventLinks, setEventLinks] = useState<{ [key: string]: string }>({});

    const navigate = useNavigate();


    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                setLoading(true);
                const data = await getAllTransactionsFromMoralis(chain || "", "0xdac17f958d2ee523a2206206994597c13d831ec7", accountNumber || "", fromBlock || "14529356");

                const filteredTransactions = data.data.result.filter((transaction: any) =>
                    transaction.token_symbol === "USDT" && parseFloat(transaction.value_decimal) > 0
                );

                const sortedTransactions = filteredTransactions.sort((a: any, b: any) =>
                    parseInt(b.block_number) - parseInt(a.block_number)
                );

                setTransactions(sortedTransactions);

                console.log(sortedTransactions)

                // Calculate balance
                const calculatedBalance = sortedTransactions.reduce((acc: number, transaction: any) => {
                    const value = parseFloat(transaction.value_decimal);
                    return transaction.to_address.toLowerCase() === accountNumber?.toLowerCase()
                        ? acc + value
                        : acc - value;
                }, 0);
                setBalance(calculatedBalance);


                // Fetch transaction metadata
                const metaData = await getAllTransactionMetaData(apiUrl);
                console.log(metaData)
                setTransactionMetaData(metaData);

                setLoading(false);
            } catch (err) {
                console.error("Error fetching transactions:", err);
                setError('Failed to fetch transactions');
                setLoading(false);
            }
        };

        fetchTransactions();
    }, [chain, accountNumber, fromBlock]);

    const valueColor = useColorModeValue("green.600", "green.200");
    const boxPadding = useBreakpointValue({ base: "2", md: "5" });


    const getEtherscanUrl = (transactionHash: string) => {
        // Adjust the base URL according to the network (mainnet, testnet, etc.)
        return `https://etherscan.io/tx/${transactionHash}`;
    };

    // Create a map of paid transactions
    const paidTransactionsMap = useMemo(() => {
        const map = new Map();
        transactionMetaData.forEach(transaction => {
            map.set(transaction.transactionHash, transaction);
        });
        return map;
    }, [transactionMetaData]);

    // Function to generate event link
    const getEventLinkAndNavigateToLink = useCallback(async (eventID: string) => {
        if (eventLinks[eventID]) {
            return eventLinks[eventID];
        }

        try {
            const event = await getEventById(eventID);
            console.log(event)
            const link = `/event/${event.loggerID}/${event.eventNumber}`;
            // setEventLinks(prev => ({ ...prev, [eventID]: link }));

            console.log(link)

            navigate(link);
            // return link;
        } catch (error) {
            console.error("Error fetching event details:", error);
            return '#'; // Return a fallback link or null
        }
    }, [eventLinks]);


    return (
        <>
            <NavBarLogic />

            <Container maxW="container.xl" centerContent p={4}>
                <Box w="full" bg="gray.50" p={boxPadding} borderRadius="lg" borderWidth="1px" shadow="md" mt={10}>
                    <Text fontSize="xl" mb="4">USDT Transactions</Text>

                    <Text>Account Number: {accountNumber}</Text>
                    <Text>Balance: {balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>

                    {loading && <Text>Loading transactions...</Text>}
                    {!loading && error && <Text color="red.500">{error}</Text>}
                    {!loading && moralisTransactions.length === 0 && <Text>No transactions found.</Text>}
                    {!loading && moralisTransactions.length > 0 && (
                        <VStack spacing={4} align="stretch">
                            {moralisTransactions.map((transaction, index) => {
                                const isIncoming = transaction.to_address.toLowerCase() === accountNumber?.toLowerCase();
                                const colorScheme = isIncoming ? 'green' : 'red';
                                const usdValue = parseFloat(transaction.value_decimal).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD'
                                });
                                const audValue = (parseFloat(transaction.value_decimal) * usdToAudRate).toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD'
                                });

                                const paidTransaction = paidTransactionsMap.get(transaction.transaction_hash);

                                return (
                                    <Box key={index} p={boxPadding} shadow="sm" borderWidth="1px" borderRadius="lg" borderColor={colorScheme}>
                                        <Flex justifyContent="space-between" alignItems="center">
                                            <Text fontSize="md" fontWeight="bold">
                                                {isIncoming ? 'Incoming Transaction' : 'Outgoing Transaction'}
                                                <Badge ml="2" colorScheme={colorScheme}>
                                                    {isIncoming ? 'Credit' : 'Debit'}
                                                </Badge>
                                                {paidTransaction && (
                                                    <Badge ml="2" colorScheme="green">Paid</Badge>
                                                )}
                                            </Text>
                                            <Text fontWeight="bold" color={valueColor}>
                                                {usdValue} (AUD: {audValue})
                                            </Text>
                                        </Flex>
                                        <Text fontWeight="bold">
                                            TransactionID/Hash:
                                            <Link
                                                href={getEtherscanUrl(transaction.transaction_hash)}
                                                isExternal
                                                color="blue.500"
                                                ml={2}
                                            >
                                                {transaction.transaction_hash}
                                            </Link>
                                        </Text>
                                        <Text fontWeight="bold">
                                            From: <Text as="span" fontWeight="normal">{transaction.from_address}</Text>
                                        </Text>
                                        <Text fontWeight="bold">
                                            To: <Text as="span" fontWeight="normal">{transaction.to_address}</Text>
                                        </Text>
                                        <Text fontWeight="bold">
                                            Date: <Text as="span" fontWeight="normal">{new Date(transaction.block_timestamp).toLocaleString()}</Text>
                                        </Text>
                                        {paidTransaction && paidTransaction.eventIDs.length > 0 && (
                                            <Button
                                                onClick={async () => {
                                                    const link = await getEventLinkAndNavigateToLink(paidTransaction.eventIDs[0]);
                                                }}
                                                colorScheme="blue"
                                                size="sm"
                                                mt={2}
                                            >
                                                View Event
                                            </Button>
                                        )}
                                    </Box>
                                );
                            })}
                        </VStack>
                    )}
                </Box>

                <Box w="full" bg="gray.50" p={boxPadding} borderRadius="lg" borderWidth="1px" shadow="md" mt={10}>
                    <Text fontSize="xl" mb="4">Transaction Metadata</Text>
                    {loading && <Text>Loading transaction metadata...</Text>}
                    {!loading && transactionMetaData.length === 0 && <Text>No transaction metadata found.</Text>}
                    {!loading && transactionMetaData.length > 0 && (
                        <Table variant="simple">
                            <TableCaption>Transaction Metadata</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Transaction Hash</Th>
                                    <Th>Logger ID</Th>
                                    <Th>Description</Th>
                                    <Th>Event IDs</Th>
                                    <Th>Created At</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {transactionMetaData.map((transaction, index) => (
                                    <Tr key={index}>
                                        <Td>
                                            <Link
                                                href={getEtherscanUrl(transaction.transactionHash)}
                                                isExternal
                                                color="blue.500"
                                            >
                                                {/* {transaction.transactionHash.substring(0, 10)}... */}
                                                {transaction.transactionHash}
                                            </Link>
                                        </Td>
                                        <Td>{transaction.loggerID}</Td>
                                        <Td>{transaction.description || 'N/A'}</Td>
                                        <Td>{transaction.eventIDs.join(', ')}</Td>
                                        <Td>{format(new Date(transaction.createdAt), 'yyyy-MM-dd HH:mm:ss')}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    )}
                </Box>
            </Container>

            <Footer />
        </>
    );
};