// src/components/NewEventComponent.tsx
import {
    Box,
    Spacer,
    Card,
    CardBody,
    Image,
    Stack,
    Heading,
    Text,
    Divider,
    CardFooter,
    ButtonGroup,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Select,
    SimpleGrid,
    RadioGroup,
    CheckboxGroup,
    Checkbox,
    Flex,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useToast,
    Switch,
    VStack,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";

import { Photo, Logger, Event } from '../types';
import { useAuth } from '../contexts/AuthContext';

import { createEvent } from '../utils/eventService';
import { deletePhoto, fetchPendingPhotos } from '../utils/photoService';
import { getApiUrl } from '../utils/helpers';
import { UploadFromGallery } from './UploadFromGallery';




interface NewEventComponentmProps {
    loggerID: Logger['loggerID'];
    // pendingPhotos: Photo[];
    userAddress: string;
    // onSubmit: (logData: LogData) => Promise<void>;
    // onUpdate: (ipfsLink: Photo['ipfsLink'], newDescription: Photo['description']) => Promise<void>;
}

export const NewEventComponent = ({ loggerID, userAddress }: NewEventComponentmProps) => {
    const apiUrl = getApiUrl();
    const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const { auth } = useAuth(); // Use the useAuth hook to access auth object
    const [descriptions, setDescriptions] = useState<{ [key: string]: string }>({});
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const toast = useToast();
    const [backdate, setBackdate] = useState(false);
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [pendingPhotos, setPendingPhotos] = useState<Photo[]>([]); // State for pending photos
    const [isBill, setIsBill] = useState(false);
    const [billAmount, setBillAmount] = useState('');
    const [billCurrency, setBillCurrency] = useState('AUD');
    const [billDueDate, setBillDueDate] = useState<Date | null>(null);

    const [triggerPhotoRefetch, setTriggerPhotoRefetch] = useState(false);



    window.onload = function () {
        const savedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (savedScrollPosition !== null) {  // Check if the value is not null
            // Parse the string to an integer and use it
            window.scrollTo(0, parseInt(savedScrollPosition, 10));
            // Optionally, clear the saved scroll position
            sessionStorage.removeItem('scrollPosition');
        }
    }




    useEffect(() => {
        const fetchPhotos = async () => {
            console.log("Fetching pending photos");
            if (!auth.userAddress) return;
            try {
                const photos = await fetchPendingPhotos(auth.userAddress, apiUrl);
                setPendingPhotos(photos);
                console.log(photos);
            } catch (error) {
                console.error("Failed to fetch pending photos:", error);
            }
        };

        fetchPhotos();
    }, [apiUrl, auth.userAddress, triggerPhotoRefetch]);


    useEffect(() => {
        if (selectedPhotos.length === 0 && pendingPhotos.length > 0) {
            setSelectedPhotos([]);
        }
    }, [pendingPhotos, triggerPhotoRefetch]);





    // Effect to initialize the descriptions from pendingPhotos on component mount
    useEffect(() => {
        const initialDescriptions = pendingPhotos.reduce((acc, photo) => ({
            ...acc,
            [photo.ipfsLink]: photo.description || ''
        }), {});
        setDescriptions(initialDescriptions);
    }, [pendingPhotos, triggerPhotoRefetch]);

    // Handler to update the description in state
    const handleDescriptionChange = (ipfsLink: string, newDescription: string) => {
        setDescriptions(prev => ({ ...prev, [ipfsLink]: newDescription }));
    };


    useEffect(() => {
        const initialDescriptions = pendingPhotos.reduce((acc, photo) => ({
            ...acc,
            [photo.ipfsLink]: photo.description || ''  // Ensures a fallback to an empty string
        }), {});
        setDescriptions(initialDescriptions);
    }, [pendingPhotos]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (selectedPhotos.length === 0) {
            alert("Please select at least one photo.");
            return;
        }
    
        const manHours = hours + minutes / 60;
    
        // Constructing eventData with the correct types explicitly set
        const eventData: Partial<Event> = {
            name,
            description,
            photoLinks: selectedPhotos,
            loggerID: loggerID,
            manHours: manHours,
            addressETH: userAddress,
            eventDate: backdate ? eventDate || undefined : undefined,        };
    
        // Conditionally adding properties only when they are applicable
        if (isBill) {
            eventData.eventType = 'BILL';
            eventData.billDetails = {
                amount: parseFloat(billAmount),
                currency: billCurrency,
                dueDate: billDueDate || undefined,
                paymentStatus: 'UNPAID'
            };
        } else {
            eventData.eventType = 'WORK';
        }
    
        console.log('Creating event with data:', eventData);
    
        try {
            const newEvent = await createEvent(apiUrl, eventData);
            console.log('Event created successfully:', newEvent);
    
            toast({
                title: "Event saved.",
                description: "Man hours have been recorded successfully.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
    
            setTimeout(function () {
                reloadAndReadPosition()
            }, 9000);
    
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    const handlePhotoSelection = (ipfsLink: string) => {
        setSelectedPhotos((prevSelectedPhotos) =>
            prevSelectedPhotos.includes(ipfsLink)
                ? prevSelectedPhotos.filter((link) => link !== ipfsLink)
                : [...prevSelectedPhotos, ipfsLink]
        );
    };

    const handleDeletePhoto = async (photoToDelete: Photo["ipfsLink"]) => {
        try {
            await deletePhoto(photoToDelete, apiUrl, auth.token!);

            console.log('Photo deleted successfully');
            toast({
                title: "Photo deleted.",
                description: "The photo has been deleted successfully.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });

            setTriggerPhotoRefetch(prev => !prev)

            // reloadAndReadPosition()
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    };


    function reloadAndReadPosition() {
        // Save the current scroll position to sessionStorage as a string
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        sessionStorage.setItem('scrollPosition', scrollPosition.toString());

        // Reload the page
        window.location.reload();
    }





    return (
        <Box >



            <Box borderWidth='1px' p={4} borderRadius='lg' borderColor='gray.200' maxW={"auto"}>

                <Box>



                    <Heading as='h6' size='xs'>
                        Step 1: Upload Photos with descriptions...
                    </Heading>



                    <Flex direction={"column"} p={4}>

                        <UploadFromGallery userAddress={auth.userAddress} onSuccessfulUpload={() => setTriggerPhotoRefetch(prev => !prev)} />

                    </Flex>
                </Box>

                <Box as="form" >

                    <Stack spacing={3}>
                        <FormControl >

                            <FormLabel>or if you have already uploaded then checkmark as many photos as necessary to support the event.</FormLabel>
                            <CheckboxGroup value={selectedPhotos} onChange={(values) => setSelectedPhotos(values as string[])}>
                                <SimpleGrid columns={[2, null, 3]} spacing="10px">
                                    {pendingPhotos.map((photo) => (
                                        <Box key={photo.ipfsLink} flexDirection={"column"}>

                                            <Image src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${photo.ipfsLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`} alt={photo.description} />
                                            <Flex p={2}>
                                                <Checkbox value={photo.ipfsLink}>
                                                    {photo.description || 'No Description'}
                                                </Checkbox>
                                                <Spacer />
                                                <Button variant='solid' colorScheme='red' size='xs' onClick={() => handleDeletePhoto(photo.ipfsLink)}>
                                                    Delete
                                                </Button>


                                            </Flex>

                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </CheckboxGroup>


                        </FormControl>

                        <Box>



                            <Heading as='h6' size='xs'>
                                Step 2: Name your event and add any other comments if necessary...
                            </Heading>
                        </Box>
                        <FormControl isRequired>
                            <FormLabel>Event Name</FormLabel>
                            <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="" />
                        </FormControl>

                        <Box p={4}>

                            <FormControl isRequired>
                                <FormLabel htmlFor='hours'>Hours</FormLabel>
                                <NumberInput min={0} onChange={value => setHours(parseInt(value))}>
                                    <NumberInputField id='hours' />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel htmlFor='minutes'>Minutes</FormLabel>
                                <NumberInput min={0} max={59} onChange={value => setMinutes(parseInt(value))}>
                                    <NumberInputField id='minutes' />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>



                        </Box>





                        <FormControl>
                            <FormLabel>Comments</FormLabel>
                            <Textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="" />
                        </FormControl>


                        <FormControl>
                            <FormLabel>Backdate Event</FormLabel>
                            <Switch isChecked={backdate} onChange={(e) => setBackdate(e.target.checked)} />
                            {backdate && (
                                <Box mt={4}>
                                    <FormLabel>Event Date</FormLabel>
                                    <DatePicker selected={eventDate} onChange={(date) => setEventDate(date)} />
                                </Box>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormLabel>Event is an incoming bill</FormLabel>
                            <Switch isChecked={isBill} onChange={(e) => setIsBill(e.target.checked)} />
                            {isBill && (
                                <VStack spacing={4} mt={4} align="stretch">
                                    <FormControl>
                                        <FormLabel>Currency</FormLabel>
                                        <Select value={billCurrency} onChange={(e) => setBillCurrency(e.target.value)}>
                                            <option value="AUD">AUD</option>
                                            <option value="USD">USD</option>
                                            <option value="BTC">BTC</option>
                                            <option value="STN5">STN5</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Bill Amount</FormLabel>
                                        <NumberInput min={0} value={billAmount} onChange={(valueString) => setBillAmount(valueString)}>
                                            <NumberInputField />

                                        </NumberInput>
                                    </FormControl>



                                    <FormControl>
                                        <FormLabel>Due Date</FormLabel>
                                        <DatePicker
                                            selected={billDueDate}
                                            onChange={(date: Date | null) => setBillDueDate(date)}
                                            customInput={<Input />}
                                        />
                                    </FormControl>
                                </VStack>
                            )}
                        </FormControl>
                        <FormControl isRequired>

                            <Flex direction="row" >
                                <Spacer />
                                <Button mt={4} colorScheme="yellow" type="submit" onClick={handleSubmit}>Submit Event</Button>
                            </Flex>

                        </FormControl>

                    </Stack>


                </Box>

            </Box>


        </Box>

    );
};

