import { Button, Stack, Text, Box, Container, Heading, Spacer, Image, Center, Link as ChakraLink } from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'

// interface StatProps {
//     label: string
//     description: string
//     value: string
//     cta: string
// }

export const AboutUs = () => {
    // const { label, description, value, cta } = props
    return (
        <Box bg="bg.surface">
            <Container py={{ base: '16', md: '24' }}>
                <Stack spacing={{ base: '12', md: '16' }} textAlign="center" align="center">
                    <Stack spacing={{ base: '4', md: '5' }}>
                        <Heading size={{ base: 'sm', md: 'md' }}>Track in Three Steps</Heading>
                        {/* <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted" maxW="3xl">
                            To foster a nationwide ecosystem of AI developers, enthusiasts, and researchers dedicated to building a decentralized and open AI infrastructure, ensuring that AI benefits all sectors of Australian society equally.
                        </Text> */}
                        <Center>
                            <Box alignContent='center'>
                                <Image borderRadius='lg' src='/assets/trackwhatmatters.png' alt='Track Tace Trade' />
                            </Box>

                        </Center>

                    </Stack>
                    {/* <Spacer /> */}

                    <Stack spacing={{ base: '4', md: '5' }}>
                        <Heading size={{ base: 'sm', md: 'md' }}>Get Started with Tracker</Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted" maxW="4xl">
                            Begin your adventure with us today—track, trace, and trade with ease and confidence.
                        </Text>
                        <ChakraLink href="/smslogin">
                            <Button bg="yellow.600" size="lg">Start Tracking</Button>
                        </ChakraLink>
                    </Stack>


                    {/* <Stack
          direction={{ base: 'column', md: 'row' }}
          maxW="3xl"
          width="full"
          spacing={{ base: '8', md: '4' }}
          {...(!isMobile ? { divider: <StackDivider /> } : {})}
        >
          {stats.map((stat, id) => (
            <Stat key={id} flex="1" {...stat} />
          ))}
        </Stack> */}
                </Stack>
            </Container>
        </Box>
    )
}