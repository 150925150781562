// src/pages/event.tsx
import React, { useEffect, useState } from "react";
import { Box, Container, Heading, Text, Image, Stack, Flex, Card, CardBody, Divider, CardFooter, Spacer, Center, Button, Badge } from "@chakra-ui/react";
import { useNavigate, useParams } from 'react-router-dom';
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { getEventByLoggerIDAndEventNumber, getAdditionalEventDataByEventID } from "../utils/eventService";
import { getApiUrl } from '../utils/helpers';
import { Event, AdditionalEventData, Logger } from '../types';
import { EventIDDisplay } from "../components/EventIDDisplay";
import { getPhotoDetailsByIpfsHash } from '../utils/photoService';
import { fetchUserFirstNameLastNameByEthAddress } from '../utils/userService';
import PhotoDisplayComponent from '../components/PhotoDisplayComponent';
import { Link as RouterLink } from 'react-router-dom';
import { getLogByLoggerID } from '../utils/loggerSerice';
import TokenInfoModal from "../components/TokenInforModel";
import { ExternalLinkIcon } from '@chakra-ui/icons';



export const EventPage = () => {
    const navigate = useNavigate();
    const apiUrl = getApiUrl();
    const { loggerID, eventNumber } = useParams<{ loggerID: string; eventNumber: string }>();
    const [event, setEvent] = useState<Event | null>(null);
    const [additionalEventData, setAdditionalEventData] = useState<AdditionalEventData[]>([]);
    const [agentNames, setAgentNames] = useState<Record<string, { firstName: string, lastName: string } | null>>({});
    const [logDetails, setLogDetails] = useState<Logger | null>(null);

    // Add a new state variable to store whether the next event exists
    const [nextEventExists, setNextEventExists] = useState<boolean>(false);

    console.log(loggerID, eventNumber);



    useEffect(() => {
        const fetchLogDetails = async () => {
            console.log("Fetching log details for loggerID:", loggerID);
            if (!loggerID) {
                console.log("No loggerID provided, skipping fetch");
                return;
            }
            try {
                console.log("Calling getLogByLoggerID with:", apiUrl, loggerID);
                const log = await getLogByLoggerID(apiUrl, loggerID);
                console.log("Received log details:", log);
                setLogDetails(log);
            } catch (error) {
                console.error("Failed to fetch log details:", error);
            }
        };

        fetchLogDetails();
    }, [apiUrl, loggerID]);


    // Function to check if the next event exists
    const checkIfNextEventExists = async () => {
        if (eventNumber && loggerID) {
            const nextEventNumber = parseInt(eventNumber) + 1;
            try {
                const nextEvent = await getEventByLoggerIDAndEventNumber(apiUrl, loggerID, nextEventNumber);
                setNextEventExists(!!nextEvent);
            } catch (error) {
                console.error("Failed to fetch next event:", error);
                setNextEventExists(false);
            }
        }
    };

    // Call the checkIfNextEventExists function whenever the eventNumber changes
    useEffect(() => {
        checkIfNextEventExists();
    }, [eventNumber]);

    useEffect(() => {
        const fetchEvent = async () => {
            if (!loggerID || !eventNumber) return;
            try {
                const event = await getEventByLoggerIDAndEventNumber(apiUrl, loggerID, parseInt(eventNumber));
                setEvent(event);

                if (event?.eventID) {
                    const additionalData = await getAdditionalEventDataByEventID(apiUrl, event.eventID);
                    setAdditionalEventData(additionalData);
                }
            } catch (error) {
                console.error("Failed to fetch event:", error);
            }
        };

        fetchEvent();
    }, [apiUrl, loggerID, eventNumber]);

    useEffect(() => {
        const fetchAgentNames = async () => {
            const uniqueAddresses = [
                event?.addressETH,
                ...additionalEventData.map(data => data.addressETH)
            ].filter(Boolean) as string[];

            const namesPromises = uniqueAddresses.map(async (addressETH) => {
                const names = await fetchUserFirstNameLastNameByEthAddress(addressETH, apiUrl);
                return { addressETH, names };
            });

            try {
                const namesArray = await Promise.all(namesPromises);
                const namesMap = namesArray.reduce((acc, { addressETH, names }) => {
                    acc[addressETH] = names;
                    return acc;
                }, {} as Record<string, { firstName: string, lastName: string } | null>);
                setAgentNames(namesMap);
            } catch (error) {
                console.error('Error fetching agent names:', error);
            }
        };

        if (event?.addressETH || additionalEventData.length > 0) {
            fetchAgentNames();
        }
    }, [event, additionalEventData, apiUrl]);

    const formatDate = (date: Date | undefined) => {
        return date ? new Date(date).toLocaleString() : '';
    };

    const getAgentName = (addressETH: string) => {
        const names = agentNames[addressETH];
        return names ? `${names.firstName} ${names.lastName}` : addressETH;
    };

    const incrementEventNumber = () => {
        if (eventNumber) {
            const nextEventNumber = parseInt(eventNumber) + 1;
            navigate(`/event/${loggerID}/${nextEventNumber}`);
        }
    };

    const decrementEventNumber = () => {
        if (eventNumber) {
            const prevEventNumber = parseInt(eventNumber) - 1;
            if (prevEventNumber >= 1) {
                navigate(`/event/${loggerID}/${prevEventNumber}`);
            }
        }
    };

    const handleShowTransactions = () => {
        if (logDetails?.managementAccountDetails?.accountAddress) {
            const transactionUrl = `/transactions/${loggerID}/${logDetails.managementAccountDetails.accountAddress}/${logDetails.managementAccountDetails.chain}/${logDetails.managementAccountDetails.fromBlock}`;
            window.open(transactionUrl, '_blank');
        }
    };



    return (
        <>
            <NavBarLogic />
            <Box as="section" bg="gray.50" m={1} p={1}>
                <Container py={{ base: "2", md: "4" }} maxW={800}>
                    <Stack spacing={{ base: "2", md: "2" }}>
                        {logDetails && (
                            <Stack spacing={{ base: "4", md: "5" }} align="center">
                                <Image
                                    src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${logDetails.photoLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`}
                                    alt={logDetails.description}
                                    borderRadius='lg'
                                />
                                <Heading size='md'>{logDetails.name}</Heading>
                                <EventIDDisplay eventID={loggerID!} eventOrLoggerID="loggerID" />
                                <Button
                                    onClick={handleShowTransactions}
                                    colorScheme="blue"
                                    m={4}
                                    rightIcon={<ExternalLinkIcon />}
                                >
                                    Show All Transactions
                                </Button>
                            </Stack>
                        )}
                        <Card maxW={900} dropShadow={"md"}>
                            <CardBody>
                                {eventNumber && <Flex>
                                    <Button
                                        onClick={decrementEventNumber}
                                        isDisabled={parseInt(eventNumber) <= 1}
                                        p='4'
                                        bg='green.400'
                                        width={150}
                                        borderRadius={5}
                                    >
                                        Previous Event
                                    </Button>
                                    <Spacer />
                                    {/* todo: if last event disable next event button */}
                                    <Button
                                        onClick={incrementEventNumber}
                                        p='4'
                                        bg='green.400'
                                        width={150}
                                        borderRadius={5}
                                        isDisabled={!nextEventExists}
                                    >
                                        Next Event
                                    </Button>
                                </Flex>}

                                {/* todo fix rounter link to link back to the  */}
                                <RouterLink to={`/log/${loggerID}`}>
                                    <Center><Button colorScheme={"blue"} m={4} >View All Events in Cronological Order</Button></Center>
                                </RouterLink>




                                {event && <Flex>
                                    <Box p='4' >
                                        <Text fontSize='xl' fontWeight='bold'>
                                            <Badge maxWidth={"auto"} ml='1' fontSize='0.8em' colorScheme='green'>{event.eventNumber}</Badge>
                                        </Text>
                                    </Box>
                                    <Spacer />
                                    {event.eventType === "BILL" ? (
                                        <Box p='4' borderRadius={5} bg={event.billDetails?.paymentStatus === "PAID" ? 'green.400' :
                                            event.billDetails?.paymentStatus === "UNPAID" ? 'red.400' : 'gray.400'}>
                                            <Text><b>Event Type: </b>{event.eventType} </Text>
                                            <Text><b>Bill Status: </b>{event.billDetails?.paymentStatus || 'Not Available'}</Text>
                                        </Box>
                                    ) : (
                                        <Box p='4' borderRadius={5} bg="gray.400">
                                            <Text><b>Event Type: </b>{event.eventType}</Text>
                                        </Box>
                                    )}
                                </Flex>}

                                <Center>
                                    <Heading size="sm">{event?.name}</Heading>
                                </Center>


                                <Stack mt='6' spacing='3'>
                                    {event?.photoLinks?.map((ipfsHash, index) => (
                                        <PhotoDisplayComponent key={index} ipfsHash={ipfsHash} />
                                    ))}
                                </Stack>
                                <Divider />
                                <CardFooter>
                                    <Flex direction={'column'}>
                                        <Flex direction={'column'}>
                                            <Text><b>Event Type: </b>{event?.eventType}</Text>
                                            {event?.eventID && (
                                                <EventIDDisplay eventID={event.eventID} eventOrLoggerID="event" />
                                            )}
                                            <Text><b>Comments: </b>{event?.description}</Text>
                                            {event?.addressETH && <Text><b>Agent: </b>{getAgentName(event.addressETH)}</Text>}
                                            <Text><b>Hours worked: </b>{event?.manHours}</Text>
                                            <Text>
                                                <b>Event Date: </b>
                                                {event?.eventDate ? new Date(event.eventDate).toLocaleDateString() : 'Event happened the same day it was reported.'}
                                            </Text>
                                        </Flex>
                                        <Flex direction={'column'}>
                                            <Box p={2} mt={4}>
                                                <Text><b>Additional Event Data</b></Text>
                                                {additionalEventData.map((data, index) => (
                                                    data.addressETH && (
                                                        <Box key={index} borderWidth={1} p={2} borderRadius={5} mb={5}>
                                                            <Text><b>Agent Reporting: </b>{getAgentName(data.addressETH)} {data.manHours} hours</Text>
                                                            {data.photoID.map((ipfsHash, index) => (
                                                                <PhotoDisplayComponent key={index} ipfsHash={ipfsHash} />
                                                            ))}
                                                        </Box>
                                                    )
                                                ))}
                                            </Box>
                                        </Flex>
                                    </Flex>


                                </CardFooter>

                                {eventNumber && <Flex>
                                    <Button
                                        onClick={decrementEventNumber}
                                        isDisabled={parseInt(eventNumber) <= 1}
                                        p='4'
                                        bg='green.400'
                                        width={150}
                                        borderRadius={5}
                                    >
                                        Previous Event
                                    </Button>
                                    <Spacer />
                                    {/* todo: if last event disable next event button */}
                                    <Button
                                        onClick={incrementEventNumber}
                                        p='4'
                                        bg='green.400'
                                        width={150}
                                        borderRadius={5}
                                        isDisabled={!nextEventExists}
                                    >
                                        Next Event
                                    </Button>
                                </Flex>}
                                <RouterLink to={`/log/${loggerID}`}>
                                    <Center><Button colorScheme={"blue"} m={4} >View All Events in Cronological Order</Button></Center>
                                </RouterLink>

                            </CardBody>
                        </Card>
                    </Stack>
                </Container>
            </Box>
            <Box id="footer">
                <Footer />
            </Box>
        </>
    );
};