import { getApiUrl } from "./helpers";

// Function to get token owners from the backend server
export const getTokenOwners = async (tokenAddress: string): Promise<any> => {
    const apiUrl = getApiUrl();  // Assuming this helper function returns your API's base URL
    try {
        const response = await fetch(`${apiUrl}/api/token-owners-stn5-eth-and-polygon/${tokenAddress}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch token owners');
        }

        const data = await response.json();
        console.log("Token owners:", data);
        return data;
    } catch (error) {
        console.error("Error fetching token owners:", error);
        throw error;  // Re-throw the error to handle it in the calling function or to notify the user
    }
};


// Function to get all transactions for a specific ERC20 token address
export const getAllTransactionsFromMoralis = async (
    chain: string,
    tokenAddress: string,
    accountAddress: string,
    fromBlock: string  // Ensure this is passed as a string or handle conversion appropriately
): Promise<any> => {
    const apiUrl = getApiUrl();  // Assuming this helper function returns your API's base URL
    try {
        const url = `${apiUrl}/api/get-all-transactions/${chain}/${tokenAddress}/${accountAddress}/${fromBlock}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch transactions: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log("Transactions data:", data);
        return data;
    } catch (error) {
        console.error("Error fetching transactions:", error);
        throw error;  // Re-throw the error to handle it in the calling function or to notify the user
    }
};