//src/utils/eventService.ts
import { Event, AdditionalEventData } from '../types';  // Import your Event type


// Function to create a new event
export const createEvent = async (apiUrl: string, eventData: Partial<Event>): Promise<Event> => {
    console.log('Creating event with data from service: :', eventData);
    try {
        const response = await fetch(`${apiUrl}/api/events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventData)
        });

        if (!response.ok) {
            throw new Error('Failed to create event');
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating event:', error);
        throw error;
    }
};

// Function to get all events for a specific loggerID
export const getEventsByLoggerID = async (apiUrl: string, loggerID: string): Promise<Event[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/logs/${loggerID}/events`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch events for loggerID');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching events for loggerID:', error);
        throw error;
    }
};

// New function to get event by loggerID and eventID
export const getEventByLoggerIDAndEventID = async (apiUrl: string, loggerID: string, eventID: string): Promise<Event | null> => {
    try {
        console.log('Fetching event with loggerID:', loggerID, 'and eventID:', eventID);
        const response = await fetch(`${apiUrl}/api/events/logger/${loggerID}/eventID/${eventID}`);
        if (!response.ok) {
            throw new Error('Failed to fetch event');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch event:', error);
        return null;
    }
};


// Function to get additional event data for a specific eventID
export const getAdditionalEventDataByEventID = async (apiUrl: string, eventID: string): Promise<AdditionalEventData[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/events/${eventID}/additional-event-data`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch additional event data for eventID');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching additional event data for eventID:', error);
        throw error;
    }
};

// Function to add additional event data to an existing event
export const addAdditionalEventData = async (apiUrl: string, eventID: string, additionalEventData: Partial<AdditionalEventData>): Promise<void> => {
    try {
        const response = await fetch(`${apiUrl}/${eventID}/add-additional-event-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(additionalEventData)
        });

        if (!response.ok) {
            throw new Error('Failed to add additional event data');
        }
    } catch (error) {
        console.error('Error adding additional event data:', error);
        throw error;
    }
};

// functiion to delete an additional event data by ID 
// export const deleteAdditionalEventDataByEventID(apiUrl: string, eventID: string) 
// Function to delete additional event data by event ID
export const deleteAdditionalEventDataByEventID = async (apiUrl: string, eventID: string): Promise<void> => {
    try {
        const response = await fetch(`${apiUrl}/api/events/${eventID}/delete-additional-event-data`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to delete additional event data for eventID: ${eventID}`);
        }
    } catch (error) {
        console.error('Error deleting additional event data:', error);
        throw error;
    }
};



// Function to get events with unpaid bills for a specific loggerID
export const getUnpaidBillEventsByLoggerID = async (apiUrl: string, loggerID: string): Promise<Event[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/logs/${loggerID}/eventswithunpaidbills`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch unpaid bill events');
        }

        const events: Event[] = await response.json();
        return events;
    } catch (error) {
        console.error('Error fetching unpaid bill events for loggerID:', error);
        throw error;
    }
};



export const getEventByLoggerIDAndEventNumber = async (apiUrl: string, loggerID: string, eventNumber: number): Promise<Event | null> => {
    try {
        console.log('Fetching event with loggerID:', loggerID, 'and eventNumber:', eventNumber);
        const response = await fetch(`${apiUrl}/api/events/logger/${loggerID}/event/${eventNumber}`);
        if (!response.ok) {
            throw new Error('Failed to fetch event');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch event:', error);
        return null;
    }
};