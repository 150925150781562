import React from "react";
import {
    Box,
    Container,
    Button,
    ButtonGroup,
    HStack,
    useBreakpointValue,
    useDisclosure,
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Logo } from "../components/Logo";
import { ToggleButton } from "../components/ToggleButton";
import { MobileDrawer } from "../components/MobileNavbar";
import { useNavigate, useLocation } from 'react-router-dom';
import { useName } from "../contexts/NameContext";





export const NavBarLogic = () => {
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const mobileNavbar = useDisclosure();

    // Inside your component
    const navigate = useNavigate();
    const location = useLocation();
    const onClose = mobileNavbar.onClose; // Assuming onClose is defined to close the drawer



    const handleLinkClick = (path: string) => {
        if (path.startsWith("#")) {
            // It's an anchor link, handle with window.location for in-page navigation
            const location = window.location.toString();
            window.location.href = location.substring(0, location.indexOf('#')) + path;
        } else {
            // It's a normal route, handle with react-router
            navigate(path);
        }
        onClose(); // Close the drawer if open
    };



    return (
        <>

            <div>
                <Box as="section" id="logo">
                    <Box
                        borderBottomWidth="1px"
                        bg="bg.surface"
                        position="relative"
                        zIndex="tooltip"
                    >
                        <Container py="4">
                            <HStack justify="space-between">
                                <ChakraLink href="/">
                                    <Logo />
                                </ChakraLink>

                                {isDesktop ? (
                                    <HStack spacing="8">
                                        <ButtonGroup
                                            size="lg"
                                            variant="text"
                                            colorScheme="gray"
                                            spacing="8"
                                        >
                                            {/* {["Team", "Opportunities", "Learn More"].map((item) => { */}
                                            {["New Log", "Logs", "Invite"].map((item) => {
                                                let path = "/";
                                                if (item === "New Log") path = "/newlog";
                                                // if (item === "Team") path = "/team";

                                                if (item === "Logs") path = "/dashboard";
                                                if (item === "Invite") path = "/invite";

                                                return (
                                                    <ChakraLink
                                                        key={item}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleLinkClick(path);
                                                        }}
                                                        style={{ width: "100%", display: "inline-flex" }}
                                                    >
                                                        <Button
                                                            size="lg"
                                                            variant="text"
                                                            colorScheme="gray"
                                                            width="100%"
                                                        >
                                                            {item}
                                                        </Button>
                                                    </ChakraLink>
                                                );
                                            })}
                                        </ButtonGroup>
                                        <ChakraLink href="/smslogin">
                                            <Button bg="yellow.600">Start Now</Button>
                                        </ChakraLink>
                                    </HStack>
                                ) : (
                                    <>
                                        <ToggleButton
                                            onClick={mobileNavbar.onToggle}
                                            isOpen={mobileNavbar.isOpen}
                                            aria-label="Open Menu"
                                        />
                                        <MobileDrawer
                                            isOpen={mobileNavbar.isOpen}
                                            onClose={mobileNavbar.onClose}
                                        />
                                    </>
                                )}
                            </HStack>
                        </Container>
                    </Box>
                </Box>


            </div>

        </>
    )
}