// AllLogs.tsx
import { useEffect, useState } from 'react';
import {
    Box,
    Card,
    Image,
    Stack,
    Heading,
    Text,
    Divider,
    Button,
    Link as ChakraLink,
    SimpleGrid,
    Flex,
    Spacer,
} from '@chakra-ui/react';

import { Logger } from '../types';
import { getAllLogs, getLogsByPermissions } from '../utils/loggerSerice';
import { getApiUrl } from '../utils/helpers';
import { useUser } from '../contexts/UserContext';

export const AllLogs = () => {
    const apiUrl = getApiUrl();
    const [logs, setLogs] = useState<Logger[]>([]);
    const user = useUser(); // Use the user data from context

    useEffect(() => {
        const fetchLogs = async () => {
            console.log('Fetching logs...');
            console.log('User:', user.permissions);

            // Check if user exists and has permissions todo: put in the same formate ats: localhost:8090/api/logsbypermission?permissions=MPP,ENTRY_LEVEL
            if (user && user.permissions) {
                try {
                    const logsFilteredByPermission = await getLogsByPermissions(apiUrl, user.permissions);
                    setLogs(logsFilteredByPermission);
                    console.log('Fetched logs:', logsFilteredByPermission);
                } catch (error) {
                    console.error('Failed to fetch logs:', error);
                }
            } else {
                console.log('No user or permissions available, unable to fetch logs.');
                // Optionally, clear logs or handle the case appropriately
                setLogs([]);
            }
        };

        fetchLogs();
    }, [apiUrl, user]); // Include user in dependency array to refetch when user changes

    return (
        <Box p={5}>

            <Heading as='h6' size='xs'>
                All Logs
            </Heading>
            <SimpleGrid minChildWidth='300px' spacing='40px'>
                {logs.map((log) => {
                    return (
                        <Box key={log.loggerID} bg={'whitesmoke'} height={'auto'} width={'300px'} p={5} shadow='md' borderWidth='1px' borderRadius={'lg'}>
                            {log.photoLink && (
                                <Image
                                    src={`https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/${log.photoLink}?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7`}
                                    alt={log.description || 'Logger Image'}
                                    borderRadius='lg'
                                    mb={3}
                                />
                            )}

                            <Stack spacing={2}>
                                <Text fontSize='lg' fontWeight='bold'>{log.name}</Text>
                                <Text>{log.description || 'No description provided'}</Text>
                                <Text fontSize='sm'>Ethereum Address: {log.addressETH}</Text>
                                {log.createdAt && (
                                    <Text fontSize='xs'>Created: {new Date(log.createdAt).toLocaleString()}</Text>
                                )}
                                {log.updatedAt && (
                                    <Text fontSize='xs'>Updated: {new Date(log.updatedAt).toLocaleString()}</Text>
                                )}
                            </Stack>


                            <Flex>

                                <Spacer />
                                <Box p='4' >
                                    <ChakraLink href={`/log/${log.loggerID}`}>
                                        <Button colorScheme='blue'>View Log</Button>
                                    </ChakraLink>

                                </Box>
                            </Flex>

                        </Box>
                    )
                })}
            </SimpleGrid>
        </Box>
    );
};
