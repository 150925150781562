// src/contexts/userContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { fetchUserByPhoneNumber } from '../utils/userService';
import { User } from '../types';  // Make sure this path is correct\
import { getApiUrl } from '../utils/helpers';
const apiUrl = getApiUrl();

const defaultUserInfo: User = {
    addressETH: '',
    phoneNumber: ''
};

const UserContext = createContext<User>(defaultUserInfo);

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<User>(defaultUserInfo);

    useEffect(() => {
        const phoneNumber = localStorage.getItem('phoneNumber') || '';
        const userAddress = localStorage.getItem('user') || '';

        if (phoneNumber) {
            fetchUserByPhoneNumber(phoneNumber, apiUrl)
                .then(fetchedUser => {
                    if (fetchedUser) {
                        setUser(fetchedUser);
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch user:', error);
                });
        }
    }, []);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
