// loggerService.ts
import { Logger } from '../types';  // Import your Logger type

export const getAllLogs = async (apiUrl: string): Promise<Logger[]> => {
    try {
        const response = await fetch(`${apiUrl}/api/logs`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch logs');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching logs:', error);
        throw error;
    }
};

// getLogByLoggerID and return the full object that complies to this
export const getLogByLoggerID = async (apiUrl: string, loggerID: string): Promise<Logger> => {
    try {
        const response = await fetch(`${apiUrl}/api/logs/${loggerID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch log by loggerID');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching log by loggerID:', error);
        throw error;
    }
};


export const getLogsByPermissions = async (apiUrl: string, permissions: string[]): Promise<Logger[]> => {
    if (permissions.length === 0) {
        console.error('No permissions provided');
        return []; // Return empty if no permissions are provided
    }

    try {
        // Convert permissions array to comma-separated string
        const permissionsParam = permissions.join(',');

        // Updated fetch request with permissions query parameter
        const response = await fetch(`${apiUrl}/api/logsbypermission?permissions=${permissionsParam}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            const errorBody = await response.text(); // Retrieve text for non-JSON responses
            throw new Error(`Failed to fetch logs: ${response.status} ${response.statusText} - ${errorBody}`);
        }

        const logs = await response.json();
        return logs; // assuming logs are directly returned as an array of Logger objects
    } catch (error) {
        console.error('Error fetching logs with permissions:', error);
        throw error; // Re-throw the error to be handled by the caller
    }
};