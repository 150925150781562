// src/utils/newEventBackendService.ts
import axios from 'axios';
import { Event } from '../types'; // Ensure you have defined the Event type in your types file
import { getApiUrl } from './helpers';

const apiUrl = getApiUrl();

// Create a new event
export const createEvent = async (eventData: Omit<Event, '_id'>): Promise<Event> => {
    try {
        const response = await axios.post(`${apiUrl}/api/neweventsbackend`, eventData);
        return response.data;
    } catch (error) {
        console.error('Error creating event:', error);
        throw error;
    }
};

// Get all events
export const getAllEvents = async (): Promise<Event[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/neweventsbackend`);
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
};

// Get a single event by ID
export const getEventById = async (eventID: string): Promise<Event> => {
    try {
        const response = await axios.get(`${apiUrl}/api/neweventsbackend/${eventID}`);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching event:', error);
        throw error;
    }
};

// Update an event
export const updateEvent = async (id: string, eventData: Partial<Event>): Promise<Event> => {
    try {
        const response = await axios.put(`${apiUrl}/api/neweventsbackend/${id}`, eventData);
        return response.data;
    } catch (error) {
        console.error('Error updating event:', error);
        throw error;
    }
};

// Delete an event
export const deleteEvent = async (id: string): Promise<void> => {
    try {
        await axios.delete(`${apiUrl}/api/neweventsbackend/${id}`);
    } catch (error) {
        console.error('Error deleting event:', error);
        throw error;
    }
};

// Get events by logger ID
export const getEventsByLoggerId = async (loggerID: string): Promise<Event[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/neweventsbackend/logger/${loggerID}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching events by logger ID:', error);
        throw error;
    }
};

// Get events by type
export const getEventsByType = async (eventType: string): Promise<Event[]> => {
    try {
        const response = await axios.get(`${apiUrl}/api/neweventsbackend/type/${eventType}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching events by type:', error);
        throw error;
    }
};

// Update payment status
export const updatePaymentStatus = async (id: string, paymentData: { paymentStatus: string, amount: number, transactionID: string }): Promise<Event> => {
    try {
        const response = await axios.put(`${apiUrl}/api/neweventsbackend/${id}/payment`, paymentData);
        return response.data;
    } catch (error) {
        console.error('Error updating payment status:', error);
        throw error;
    }
};

// Add a signer to the event
export const addSigner = async (id: string, signer: string): Promise<Event> => {
    try {
        const response = await axios.put(`${apiUrl}/api/neweventsbackend/${id}/signer`, { signer });
        return response.data;
    } catch (error) {
        console.error('Error adding signer:', error);
        throw error;
    }
};