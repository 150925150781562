import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Heading, Text, Stack, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { NavBarLogic } from '../components/NavBarLogic';
import { Footer } from '../components/Footer';
import { useAuth } from '../contexts/AuthContext';
import Camera from '../components/Camera';


import { User, Photo } from '../types'
import { PendingPhotos } from '../components/PendingPhotos';
import { NewLogComponentForm } from '../components/NewLogComponentForm';
import { UploadFromGallery } from '../components/UploadFromGallery';

import { uploadPhoto, fetchPendingPhotos, deletePhoto, updatePhotoDescription } from '../utils/photoService';  // Import the photo service


import { getApiUrl } from '../utils/helpers';







/**
 * The main Dashboard component that renders the user's dashboard.
 * 
 * This component handles the following functionality:
 * - Checks for stored user data (token, phone number, Ethereum address) in localStorage and sets the user state accordingly.
 * - Fetches the user's data from the API based on the stored Ethereum address.
 * - Provides a button to log the user out and navigate to the login page.
 * - Renders a camera component that allows the user to capture an image and upload it to the server.
 * - Displays some static text content for the dashboard.
 */
export const NewLog = () => {
  const apiUrl = getApiUrl();
  const { auth, logout, setAuthInfo } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [userObjectFromMongo, setUserObjectFromMongo] = useState<User | null>(null);
  const [pendingPhotos, setPendingPhotos] = useState<Photo[]>([]);
  const [handlingCapture, setHandlingCapture] = useState(false);
  const [triggerPhotoRefetch, setTriggerPhotoRefetch] = useState(false);




  /**
 * Listens for changes to the `userObjectFromMongo` state and logs the updated value to the console.
 * This effect is used to monitor and respond to changes in the user object retrieved from the MongoDB database.
 */
  useEffect(() => {
    if (userObjectFromMongo) {
      console.log("Updated userObjectFromMongo:", userObjectFromMongo);
    }
  }, [userObjectFromMongo]); // Dependency array includes userObjectFromMongo to listen for changes


  /**
 * This effect checks for stored user data in the browser's local storage and fetches the user's information if it exists.
 * 
 * - If there is a stored Ethereum address, it calls `fetchUserByEthAddress` to retrieve the user's data.
 * - If there are stored token, phone number, and Ethereum address, it creates a new `User` object with the stored data and sets it in the component's state.
 * - It also sets the `authInfo` state with the stored token, phone number, Ethereum address, and login method.
 */
  useEffect(() => {
    const storedToken = localStorage.getItem('token') ?? '';
    const storedPhone = localStorage.getItem('phoneNumber') ?? '';
    const storedAddress = localStorage.getItem('user') ?? ''; // Ensure you store and retrieve the same 
    if (!storedToken || !storedPhone || !storedAddress) {
      navigate('/smslogin');
    } else {
      const fetchData = async () => {
        try {
          const userFromMongo = await fetchUserByEthAddress(storedAddress);
          if (userFromMongo) {
            setUserObjectFromMongo(userFromMongo);
          } else {
            console.log("User not already saved with MongoDB");
          }
        } catch (error) {
          console.error("Failed to fetch user:", error);
        }
      };
      fetchData();
    }
  }, [navigate]);



  /**
 * Fetches and sets pending photos when the `userObjectFromMongo` or `handlingCapture` dependencies change.
 */
  useEffect(() => {
    fetchAndSetPendingPhotos();
  }, [userObjectFromMongo, handlingCapture, triggerPhotoRefetch]);  // Re-fetch when userObjectFromMongo or handlingCapture changes






  /**
 * Fetches a user by their Ethereum address.
 *
 * @param ethAddress - The Ethereum address of the user to fetch.
 * @returns The user data, or `null` if an error occurs during the fetch.
 */
  const fetchUserByEthAddress = async (ethAddress: User["addressETH"]) => {
    console.log("Fetching user by ETH address...");
    try {
      const response = await fetch(`${apiUrl}/api/users/by-eth?addressETH=${ethAddress}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true'  // Include if you're still using ngrok
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const user = await response.json();
      console.log('Fetched user by ETH address:', user);
      setUserObjectFromMongo(user); // Save the fetched user object to state
      return user; // Return the user data
    } catch (error) {
      console.error('Error fetching user by ETH address:', error);
      return null; // Return null or handle the error as needed
    }
  };


  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };


  const handleCapture = async (blob: any) => {
    if (!userObjectFromMongo) {
      console.log("Capture operation skipped: No user defined.");
      return;
    }
    setHandlingCapture(true);
    const formData = new FormData();
    const file = new File([blob], "filename.jpg", { type: blob.type });
    formData.append('file', file);
    formData.append('ethAddress', userObjectFromMongo.addressETH);

    try {
      const result = await uploadPhoto(formData, apiUrl);
      console.log('File uploaded:', result);
      await fetchAndSetPendingPhotos();
    } catch (err) {
      console.error('Error during capture:', err);
    } finally {
      setHandlingCapture(false);
    }
  };

  const fetchAndSetPendingPhotos = async () => {
    if (!userObjectFromMongo) return;
    try {
      const photos = await fetchPendingPhotos(userObjectFromMongo.addressETH, apiUrl);
      setPendingPhotos(photos);
    } catch (error) {
      console.error('Failed to fetch or set pending photos:', error);
    }
  };



  const handleDeletePhoto = async (photoToDelete: Photo["ipfsLink"]) => {
    try {
      await deletePhoto(photoToDelete, apiUrl, auth.token!);
      setPendingPhotos(prevPhotos => prevPhotos.filter(photo => photo.ipfsLink !== photoToDelete));
      console.log('Photo deleted successfully');
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleFormSubmit = async (logData: any) => {
    try {
      const response = await fetch(`${apiUrl}/api/logs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(logData)
      });

      const responseData = await response.json(); // Parse JSON body regardless of the response status

      if (!response.ok) {
        // Handle different cases based on the status code
        if (response.status === 400) {
          console.error('Validation error:', responseData);
          alert(`Error: ${responseData.message}`); // Display more specific error to the user
        } else if (response.status === 409) {
          console.error('Conflict error:', responseData);
          alert(`Error: ${responseData.message}`); // Handle duplicate data entries, etc.
        } else {
          throw new Error(responseData.message || 'Failed to create log due to server error');
        }
      } else {
        console.log('Log created:', responseData);

        alert('Log successfully created!'); // Show success message
        navigate('/dashboard'); // Redirect to the logs page
        // Additional success handling (e.g., redirect or clear form)
      }
    } catch (error) {
      console.error('Error creating log:', error);
      alert(`Error: ${error}`); // Generic error fallback
    }
  };



  const handleUpdatePhotoDescription = async (ipfsLink: Photo["ipfsLink"], newDescription: Photo["description"]) => {
    try {
      await updatePhotoDescription(ipfsLink, newDescription, apiUrl);
      setPendingPhotos(prevPhotos => {
        return prevPhotos.map(photo => {
          if (photo.ipfsLink === ipfsLink) {
            return { ...photo, description: newDescription };
          }
          return photo;
        });
      });
    } catch (error) {
      console.error('Error updating photo:', error);
    }
  };




  return (
    <>
      <NavBarLogic />
      <Container py={{ base: "16", md: "24" }}>

        <Flex direction={"column"} p={4}>

        <Heading as='h6' size='xs'>
            Create a New Log
          </Heading>
          <Text>To initiate the creation of a new log, please upload a photo or choose one below and then provide a name and description.</Text>

          <UploadFromGallery userAddress={auth.userAddress}   onSuccessfulUpload={() => setTriggerPhotoRefetch(prev => !prev)}  />

        </Flex>
        <Stack spacing={{ base: "8", md: "10" }}>

        


          <NewLogComponentForm key={pendingPhotos.map(photo => photo.description).join(',')} pendingPhotos={pendingPhotos} onSubmit={handleFormSubmit} onUpdate={handleUpdatePhotoDescription} />

          {/* Conditional rendering of the Camera component */}
          {/* {userObjectFromMongo && <Camera onCapture={handleCapture} />} */}



          <br />

          {/* {pendingPhotos && <PendingPhotos pendingPhotos={pendingPhotos} onDelete={handleDeletePhoto} onUpdate={updatePhotoDescription} />} */}




          <Text>
            {auth.token ? `Welcome back, user ${auth.phoneNumber}!` : 'Not logged in.'}
          </Text>
          <Button colorScheme="blue" onClick={handleLogout}>
            Logout
          </Button>
        </Stack>
      </Container>
      <Footer />
    </>
  )
};